import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import "./App.css"
import Calendario from './components/Calendario'
import reportWebVitals from './reportWebVitals'
import FormAdmin from './components/adminPage'

const calendarioTCBEElement = document.getElementById('calendarioTCBE')
if (calendarioTCBEElement) {
  ReactDOM.render(<Calendario />, calendarioTCBEElement);
}

const formularioAdminElement = document.getElementById('formularioAdmin');
if (formularioAdminElement) {
  ReactDOM.render(<FormAdmin />, formularioAdminElement);
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

