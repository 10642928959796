import React, { useEffect, useState } from "react";
import SliderDias from "./SliderDias";
import CircularProgress from '@mui/material/CircularProgress';
import "../App.css"

export default function Calendario() {
    const [datosCoz, setDatosCoz] = useState()
    const [datosCal, setDatosCal] = useState()
    const urlCozumel = window.miPlugin.Cozumel
    const urlCalica = window.miPlugin.Calica

   
    useEffect(() => {
        fetch(urlCozumel)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
               
                return response.json();
            })
            .then(data => {
                // Aquí puedes trabajar con tus datos en formato JSON
                setDatosCoz(data);
                console.log(data)
                
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        fetch(urlCalica)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Aquí puedes trabajar con tus datos en formato JSON
                setDatosCal(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [])


    return (
        <div style={{ textAlign: "center" }}>
            {datosCoz && datosCal ? <SliderDias horariosCoz={datosCoz} horariosCal={datosCal} /> : <CircularProgress />}
        </div>
    )
}