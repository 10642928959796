import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function AdminPage() {

    const [urlCozumel, setUrlCozumel] = useState("")
    const [urlPdc, setUrlPdc] = useState("")
    const [datos, setDatos] = useState([])
    const [idCzm, setIdCzm] = useState()
    const [idPdc, setIdPdc] = useState()
    useEffect(() => {
        fetch('/wp-json/mi-plugin/v1/datos')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setDatos(data)
                setIdCzm(data[0].id)
                setIdPdc(data[1].id)
                setUrlCozumel(data[0].url)
                setUrlPdc(data[1].url)
                console.log(data)
            })
            .catch(error => {
                console.log('Error:', error);
            });
    }, []);

    //enviar datos
    const handleSubmit = async (event) => {
        // event.preventDefault();

        const data = {
            api_cozumel: urlCozumel,
            api_pdc: urlPdc,
            id_czm: idCzm,
            id_pdc: idPdc
        }

        console.log(JSON.stringify(data))

        try {
            const response = await fetch('/wp-json/horariosTCBE/v2/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const json = await response.json()
            console.log(json);
            alert(json)

        } catch (error) {
            console.error('There was a problem with the fetch operation: ' + error.message);
        }

        //localStorage.removeItem('datos');
    };


    return (
        <Card sx={{ width: '50%' }}>
            <CardContent>
                <Typography variant="h5" component="div" sx={{ padding: ".5rem" }}>
                    Apis Horarios
                </Typography>
                <form method="post" onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '.7rem' }}>
                    <label><strong>Api Cozumel</strong> </label>
                    <input type='hidden' name="id_czm" value={idCzm} />
                    <input type="text" name="api_cozumel" value={urlCozumel} onChange={(e) => setUrlCozumel(e.target.value)} />
                    <label><strong> Api Playa del Carmen</strong></label>
                    <input type='hidden' name="id_pdc" value={idPdc} />
                    <input type="text" name="api_pdc" value={urlPdc} onChange={(e) => setUrlPdc(e.target.value)} />
                    <Button variant="contained" type="submit" >Guardar</Button>

                </form>
            </CardContent>
        </Card>
    )
}